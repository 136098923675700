import React, { useState, useEffect } from "react";
import getBackgroundColor from "../getBackgroundColor";
import DownloadButton from "../../DownloadButton";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Typography,
  TableSortLabel,
} from "@mui/material";

const formatFecha = (fecha) => {
  const date = new Date(fecha);
  return date.toLocaleDateString(); // Devuelve solo la fecha
};

const fetchDataWithDays = async (url, setData, dateFields) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Error en la solicitud: ${response.statusText}`);
    }
    const data = await response.json();

    // Procesar los datos para añadir días restantes
    const processedData = data.map((item) => {
      const hoy = new Date();
      const daysRemaining = {};

      dateFields.forEach((field, index) => {
        if (item[field]) {
          const diferencia = new Date(item[field]) - hoy;
          daysRemaining[`dias_restantes_${index + 1}`] = Math.ceil(
            diferencia / (1000 * 60 * 60 * 24)
          );
        } else {
          daysRemaining[`dias_restantes_${index + 1}`] = null;
        }
      });

      return { ...item, ...daysRemaining };
    });

    setData(processedData);
  } catch (error) {
    console.error(`Error al obtener los datos de ${url}:`, error);
  }
};

const EquipmentTable = () => {
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "id_interno", direction: "asc" });

  useEffect(() => {
    const url = "https://api.bhsmx.com/api/spoolers";
    const dateFields = ["carga_next", "pnd_next", "mtto_next"];
    fetchDataWithDays(url, setData, dateFields);
  }, []);

  const handleSort = (key) => {
    const isAsc = sortConfig.key === key && sortConfig.direction === "asc";
    setSortConfig({ key, direction: isAsc ? "desc" : "asc" });

    const sortedData = [...data].sort((a, b) => {
      if (a[key] === null || b[key] === null) return 0; // Manejar valores nulos
      return isAsc ? a[key] - b[key] : b[key] - a[key];
    });

    setData(sortedData);
  };

  return (
    <TableContainer component={Paper}>
      <Typography variant="h5" align="center" sx={{ marginY: 2 }}>
        Tabla de Spoolers
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2}>
              <TableSortLabel
                active={sortConfig.key === "id_interno"}
                direction={sortConfig.key === "id_interno" ? sortConfig.direction : "asc"}
                onClick={() => handleSort("id_interno")}
              >
                Tag
              </TableSortLabel>
            </TableCell>
            <TableCell colSpan={3}>Prueba de Carga</TableCell>
            <TableCell colSpan={3}>PND</TableCell>
            <TableCell colSpan={3}>Mantenimiento</TableCell>
            <TableCell rowSpan={2}>
              <TableSortLabel
                active={sortConfig.key === "ubicacion"}
                direction={sortConfig.key === "ubicacion" ? sortConfig.direction : "asc"}
                onClick={() => handleSort("ubicacion")}
              >
                Ubicación
              </TableSortLabel>
            </TableCell>
            <TableCell rowSpan={2}>PDF</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Último</TableCell>
            <TableCell>Siguiente</TableCell>
            <TableCell>
              <TableSortLabel
                active={sortConfig.key === "dias_restantes_1"}
                direction={sortConfig.key === "dias_restantes_1" ? sortConfig.direction : "asc"}
                onClick={() => handleSort("dias_restantes_1")}
              >
                Faltan
              </TableSortLabel>
            </TableCell>
            <TableCell>Último</TableCell>
            <TableCell>Siguiente</TableCell>
            <TableCell>
              <TableSortLabel
                active={sortConfig.key === "dias_restantes_2"}
                direction={sortConfig.key === "dias_restantes_2" ? sortConfig.direction : "asc"}
                onClick={() => handleSort("dias_restantes_2")}
              >
                Faltan
              </TableSortLabel>
            </TableCell>
            <TableCell>Último</TableCell>
            <TableCell>Siguiente</TableCell>
            <TableCell>
              <TableSortLabel
                active={sortConfig.key === "dias_restantes_3"}
                direction={sortConfig.key === "dias_restantes_3" ? sortConfig.direction : "asc"}
                onClick={() => handleSort("dias_restantes_3")}
              >
                Faltan
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((spooler, index) => (
            <TableRow key={index}>
              <TableCell>{spooler.id_interno || "N/A"}</TableCell>
              <TableCell>
                {spooler.carga_fecha
                  ? formatFecha(spooler.carga_fecha)
                  : "Sin datos"}
              </TableCell>
              <TableCell>
                {spooler.carga_next
                  ? formatFecha(spooler.carga_next)
                  : "Sin datos"}
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: getBackgroundColor(spooler.dias_restantes_1),
                  color: "white",
                }}
              >
                {spooler.dias_restantes_1 || "N/A"} d
              </TableCell>
              <TableCell>
                {spooler.pnd_fecha
                  ? formatFecha(spooler.pnd_fecha)
                  : "Sin datos"}
              </TableCell>
              <TableCell>
                {spooler.pnd_next
                  ? formatFecha(spooler.pnd_next)
                  : "Sin datos"}
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: getBackgroundColor(spooler.dias_restantes_2),
                  color: "white",
                }}
              >
                {spooler.dias_restantes_2 || "N/A"} d
              </TableCell>
              <TableCell>
                {spooler.mtto_fecha
                  ? formatFecha(spooler.mtto_fecha)
                  : "Sin datos"}
              </TableCell>
              <TableCell>
                {spooler.mtto_next
                  ? formatFecha(spooler.mtto_next)
                  : "Sin datos"}
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: getBackgroundColor(spooler.dias_restantes_3),
                  color: "white",
                }}
              >
                {spooler.dias_restantes_3 || "N/A"} d
              </TableCell>
              <TableCell>{spooler.ubicacion || "N/A"}</TableCell>
              <TableCell>
                <DownloadButton table="spoolers" tag={spooler.id_interno} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EquipmentTable;
