import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ClientTable from './api/Tablas/client';
import ClientFabric from './api/Tablas/clientfabric';

// ICONS
import SortZa from './assets/icon/SortAz.svg';
import SortAz from './assets/icon/SortZa.svg';

// UTILITY FUNCTIONS
import sortData from './api/SortUtility.js';
import renderSortButton from './api/renderSortButton';
import DownloadButton from './DownloadButton';
import { jwtDecode } from 'jwt-decode'; 

const fetchCertificationsWithDays = async (username, tables, dateFields) => {
  const url = 'https://api.bhsmx.com/api/get-certifications';

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, tables }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al obtener las certificaciones');
    }

    const data = await response.json();

    const processedData = data.map((item) => {
      const hoy = new Date();
      const daysRemaining = {};

      dateFields.forEach((field, index) => {
        if (item[field]) {
          const diferencia = new Date(item[field]) - hoy;
          daysRemaining[`dias_restantes_${index + 1}`] = Math.ceil(diferencia / (1000 * 60 * 60 * 24));
        } else {
          daysRemaining[`dias_restantes_${index + 1}`] = null;
        }
      });

      return { ...item, ...daysRemaining };
    });

    return processedData;
  } catch (error) {
    console.error('Error en la solicitud:', error.message);
    return [];
  }
};

const ClientTables = () => {
  const [client, setClient] = useState([]);
  const [sortConfigClient, setSortConfigClient] = useState({ key: null, direction: 'ascending' });
  const [activeTable, setActiveTable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tableDataAvailable, setTableDataAvailable] = useState({ renta: false, fabricacion: false });

  useEffect(() => {
    const initializeTables = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('token');
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const rentaTables = ['spoolers', 'bml', 'canastillas', 'base_carrete'];
        const fabricacionTables = ['fabricacion'];
        const dateFields = ['carga_next', 'pnd_next'];

        const rentaData = await fetchCertificationsWithDays(username, rentaTables, dateFields);
        const fabricacionData = await fetchCertificationsWithDays(username, fabricacionTables, dateFields);

        const hasRentaData = rentaData.length > 0;
        const hasFabricacionData = fabricacionData.length > 0;

        setTableDataAvailable({
          renta: hasRentaData,
          fabricacion: hasFabricacionData,
        });

        if (hasRentaData) {
          setActiveTable('renta');
          setClient(rentaData);
        } else if (hasFabricacionData) {
          setActiveTable('fabricacion');
          setClient(fabricacionData);
        }
      } catch (error) {
        console.error('Error al inicializar tablas:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    initializeTables();
  }, []);

  const handleTabChange = async (event, newValue) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      const tables = newValue === 'renta'
        ? ['spoolers', 'bml', 'canastillas', 'base_carrete']
        : ['fabricacion'];
      const dateFields = ['carga_next', 'pnd_next'];

      const data = await fetchCertificationsWithDays(username, tables, dateFields);
      setClient(data);
      setActiveTable(newValue);
    } catch (error) {
      console.error('Error al cambiar de tabla:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getSortIcon = (sortConfig, key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? SortAz : SortZa;
    }
    return SortAz;
  };

  const formatFecha = (fecha) => {
    const date = new Date(fecha);
    return date.toLocaleDateString();
  };

  return (
    <div className="TablaEras-Container">
     <Tabs
  value={activeTable}
  onChange={handleTabChange}
  centered
  textColor="inherit"
  sx={{
    '& .MuiTabs-indicator': {
      backgroundColor: '#F4CE14', // Color personalizado
    },
    '& .MuiTab-root': {
      color: '#2E2E2E',
      fontWeight: 'bold',
      fontSize: '1.25rem', // Corregido: No hay espacio entre "2" y "rem"
      textTransform: 'none',
    },
    '& .Mui-selected': {
      color: '#2E2E2E', // Color de la pestaña seleccionada
    },
  }}
>
  <Tab
    label="Renta"
    value="renta"
    disabled={!tableDataAvailable.renta}
  />
  <Tab
    label="Fabricación"
    value="fabricacion"
    disabled={!tableDataAvailable.fabricacion}
  />
</Tabs>


      {isLoading ? (
        <div style={{ textAlign: 'center' }}>Cargando...</div>
      ) : activeTable === 'renta' ? (
        <ClientTable
          data={client}
          sortConfig={sortConfigClient}
          setData={setClient}
          setSortConfig={setSortConfigClient}
          sortData={sortData}
          formatFecha={formatFecha}
          getSortIcon={getSortIcon}
          renderSortButton={renderSortButton}
          DownloadButton={DownloadButton}
        />
      ) : (
        <ClientFabric
          data={client}
          sortConfig={sortConfigClient}
          setData={setClient}
          setSortConfig={setSortConfigClient}
          sortData={sortData}
          formatFecha={formatFecha}
          getSortIcon={getSortIcon}
          renderSortButton={renderSortButton}
          DownloadButton={DownloadButton}
        />
      )}
    </div>
  );
};

export default ClientTables;
