
import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Header from './Header.js';
import MainPage from './MainPage.js';
import Footer from './Footer.js';
import Servicios from './Servicios.js'; // Agregar el nuevo componente de Servicios
import Contacto from './Contacto.js'; // Agregar el nuevo componente de Servicios
import ScrollToTop from './ScrollToTop.js';
import PruebasElectricas from './PruebasElectricas.js';
import VDF from './vdf.js';
import Experiencia from './Experiencia.js';
import SistemaPotencia from './SistemaPotencia.js';
import BombeoHorizontal from "./BombeoHorizontal.js";
import SistemasComunicacion from './SistemasComunicacion.js';
import Bec from "./Bec.js";
import Login from "./login.js";
import Register from "./register.js";
import ProtectedRoute from './ProtectedRoute'; // Asegúrate de importar el componente
import Certificados from './certificados.js';
import AdminDashboard from "./AdminDashboard.js";
import EditCertificados from "./editCertificados.js";
import ClientTables from "./clientTables.js";
import Visualizer from "./visualizer.jsx";
import EditFiles from './editfiles.js';
import EquipmentTable from './api/Tablas/equipos.js'

import './App.css';


function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="Head-Content">
         <Header />
        <div className="Content">
          <Routes>
            <Route path="/" element={<MainPage />} /> {/* Página de Inicio */}
            <Route path="/servicios" element={<Servicios />} /> {/* Página de Servicios */}
            <Route path="/contacto" element={<Contacto />} /> {/* Página de Contacto */}
            <Route path="/pruebas-electricas" element={<PruebasElectricas />}/>{/* Página de Pruebas Electricas */}
            <Route path="/vdf" element={<VDF />}/>{/* Página de Servicio a Variadores */}
            <Route path="/experiencia" element={<Experiencia />}/>{/* Página de Experiencia*/}
            <Route path="/potencia-hidraulica" element={<SistemaPotencia />}/>{/* Página de Potencia Hidraulica*/}
            <Route path="/bombeo-horizontales" element={<BombeoHorizontal />}/>{/* Página de Bombeo Horizontales*/}
            <Route path="/sistemas-comunicacion" element={<SistemasComunicacion />}/>{/* Página de Sistemas de Comunicación*/}
            <Route path="/bec" element={<Bec />}/>{/* Página de Sistemas de Comunicación*/}
            <Route path="/login" element={<Login />}/>{/* Página de login*/}
            <Route path="/prueba" element={<EditFiles />}/>
            <Route path="/prueba2" element={<EquipmentTable />}/>
            <Route path="/re-cer" 
            element={
                        <ProtectedRoute allowedTypes={['admin']}>
                        <EditCertificados />
                        </ProtectedRoute>
            }/>
            <Route path="/visualizer" 
            element={
/*                        <ProtectedRoute allowedTypes={['admin']}>*/
                        <Visualizer />
                        /* </ProtectedRoute>*/
            }/>
            <Route
                path="/register"
                element={
                          <ProtectedRoute allowedTypes={['admin']}>
                          <Register />
                          </ProtectedRoute>
          }
        />
            <Route
                path="/dashboard"
                element={
                          <ProtectedRoute allowedTypes={['admin']}>
                          <AdminDashboard />
                          </ProtectedRoute>
          }
        />
                    <Route
                path="/certificados"
                element={
                          <ProtectedRoute allowedTypes={['admin', 'user']}>
                          <Certificados />
                          </ProtectedRoute>
          }
        />

<Route
                path="/client"
                element={
                          <ProtectedRoute allowedTypes={['admin', 'client']}>
                          <ClientTables />, 
                          </ProtectedRoute>
          }
        />
          </Routes>
        </div>
        <div className="Foot-Content">
         <Footer /> 
        </div>
      </div>
    </Router>
  );
}

export default App;
