import React, { useState } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Paper,
} from "@mui/material";
import UpdateFileForm from "./updatefiles"; // Importa tu componente de actualización de archivos
import DeleteFileForm from "./deletefiles"; // Importa tu componente de eliminación de archivos

const EditFiles = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        padding: 2,
        backgroundColor: "#f5f5f5",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Gestión de Archivos
      </Typography>

      <Paper elevation={3} sx={{ width: "100%", maxWidth: 600, marginTop: 3 }}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Actualizar Archivos" />
          <Tab label="Eliminar Archivos" />
        </Tabs>

        <Box sx={{ padding: 2 }}>
          {currentTab === 0 && <UpdateFileForm />} {/* Componente para actualizar archivos */}
          {currentTab === 1 && <DeleteFileForm />} {/* Componente para eliminar archivos */}
        </Box>
      </Paper>
    </Box>
  );
};

export default EditFiles;
