import React, { useState } from "react";
import ReCertificados from "./Re-Certificados";
import EditFiles from "./editfiles";
import { Box, Tabs, Tab, Typography } from "@mui/material";

const EditCertificados = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const TabPanel = ({ children, value, index }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Editar Certificados" />
        <Tab label="Editar Archivos" />
      </Tabs>

      <TabPanel value={activeTab} index={0}>
        <ReCertificados />
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <EditFiles />
      </TabPanel>
    </Box>
  );
};

export default EditCertificados;
