const ClientFabric = ({
    data,
    sortData,
    sortConfig,
    setData,
    setSortConfig,
    formatFecha,
    getSortIcon,
    renderSortButton,
    DownloadButton,
  }) => {
    // Función para mapear el tipo basado en id_interno
    const mapTipo = (idInterno) => {
      if (!idInterno) return 'Desconocido';
      const middlePart = idInterno.split('-')[1]; // Extrae la parte central del id
      switch (middlePart) {
        case 'CH':
          return 'Caja de Herramientas';
        case 'BSK':
          return 'Canastilla';
        // Agrega más casos según sea necesario
        default:
          return 'Tipo Desconocido';
      }
    };
  
    const createSortButtonParams = (columnKey, label, type) => ({
      columnKey,
      label,
      data: data,
      setData: setData,
      sortConfig: sortConfig,
      setSortConfig: setSortConfig,
      sortData: sortData,
      getSortIcon: getSortIcon,
      type,
    });
  
    return (
      <>
        <table className="content-table">
          <thead>
            <tr>
              <th>Tag{renderSortButton(createSortButtonParams('id_interno', 'Tag'))}</th>
              <th>Tipo{renderSortButton(createSortButtonParams('source_table', 'Tipo'))}</th>
              <th>
                Prueba de Carga
                {renderSortButton(createSortButtonParams('carga_fecha', 'Fecha de Prueba de Carga', 'IsDate'))}
              </th>
              <th>
                PND
                {renderSortButton(createSortButtonParams('pnd_fecha', 'Fecha de PND', 'IsDate'))}
              </th>
              <th>PDF</th>
            </tr>
          </thead>
          <tbody>
            {data.map((equipo, index) => (
              <tr key={index}>
                <td data-label="Tag">{equipo.id_interno || 'N/A'}</td>
                <td data-label="Tipo">{mapTipo(equipo.id_interno)}</td>
                <td data-label="Prueba de carga">
                  {equipo.carga_fecha ? formatFecha(equipo.carga_fecha) : 'Sin datos'}
                </td>
                <td data-label="PND">
                  {equipo.pnd_fecha ? formatFecha(equipo.pnd_fecha) : 'Sin datos'}
                </td>
                <td data-label="PDF">
                  <DownloadButton
                    table={equipo.source_table}
                    tag={equipo.id_interno}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };
  
  export default ClientFabric;
  