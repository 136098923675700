const ClientTable = ({
  data,
  sortData,
  sortConfig,
  setData,
  setSortConfig,
  formatFecha,
  getSortIcon,
  renderSortButton,
  DownloadButton,
}) => {
  const tableNameMapping = {
    spoolers: 'Spooler',
    bml: 'Base para Motor Lift',
    canastillas: 'Canastilla',
    base_carrete: 'Base para Carrete',
    racks: 'Rack',
    cajas_herramientas: 'Caja de Herramientas',
  };

  const transformSourceTable = (sourceTable) => {
    return tableNameMapping[sourceTable] || sourceTable; // Mapea nombres de tablas
  };

  const createSortButtonParams = (columnKey, label, type) => ({
    columnKey,
    label,
    data: data,
    setData: setData,
    sortConfig: sortConfig,
    setSortConfig: setSortConfig,
    sortData: sortData,
    getSortIcon: getSortIcon,
    type,
  });

  // Función para dividir el tag de los grilletes
  const splitTags = (input) => {
    if (!input) return { base: 'N/A', numbers: 'N/A' }; // Manejo de valores nulos

    const tags = input.split(',').map((tag) => tag.trim());
    const base = tags[0].split('-')[0]; // Base del primer elemento
    const numbers = tags.map((tag) => tag.split('-')[1]).join(' '); // Números concatenados
    return { base, numbers };
  };

  return (
    <>
      <table className="content-table">
        <thead>
          <tr>
            <th>Tag{renderSortButton(createSortButtonParams('id_interno', 'Tag'))}</th>
            <th>Tipo{renderSortButton(createSortButtonParams('source_table', 'Tipo'))}</th>
            <th>Última Prueba de Carga{renderSortButton(createSortButtonParams('carga_fecha', 'Fecha de Prueba de Carga', 'IsDate'))}</th>
            <th>Última PND{renderSortButton(createSortButtonParams('pnd_fecha', 'Fecha de PND', 'IsDate'))}</th>
            <th>Estrobo{renderSortButton(createSortButtonParams('estrobo_tag', 'Tag de Estrobo'))}</th>
            <th>Grilletes{renderSortButton(createSortButtonParams('grillete_tag', 'Tag de Grilletes'))}</th>
            <th>PDF</th>
          </tr>
        </thead>
        <tbody>
          {data.map((equipo, index) => {
            const grilletes = splitTags(equipo.grillete_tag); // Procesar el campo grillete_tag
            return (
              <tr key={index}>
                <td data-label="Tag">{equipo.id_interno || 'N/A'}</td>
                <td data-label="Tipo">{transformSourceTable(equipo.source_table) || 'N/A'}</td>
                <td data-label="Última prueba de carga">
                  {equipo.carga_fecha ? formatFecha(equipo.carga_fecha) : 'Sin datos'}
                </td>
                <td data-label="Última PND">
                  {equipo.pnd_fecha ? formatFecha(equipo.pnd_fecha) : 'Sin datos'}
                </td>
                <td data-label="Estrobo">{equipo.estrobo_tag || 'N/A'}</td>
                <td data-label="Grilletes">
                  <div className="wrapper-grilletes">
                    <span className="row-1">{grilletes.base}</span>
                    <span className="row-2">{grilletes.numbers}</span>
                  </div>
                </td>
                <td data-label="PDF">
                  <DownloadButton
                    table={equipo.source_table}
                    tag={equipo.id_interno}
                    estrobo={equipo.estrobo_tag}
                    grillete={grilletes.base}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default ClientTable;
