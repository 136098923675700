import React, { useState, useEffect } from 'react';

const OptionTag = ({ isTagEnabled, table, onSelectTags, onAssignedUpdate, maxSelects = 1, equipo_tag, onDelete, extra='', estrobo, grilletes }) => {
  const [tags, setTags] = useState({ assigned: [], unassigned: [] }); // Tags divididos en assigned y unassigned
  const [selectedTags, setSelectedTags] = useState(Array(maxSelects).fill('')); // Tags seleccionados, inicializados como vacíos

  // Función para obtener tags de la API
  const fetchTags = async (tableName) => {
    const url = `https://api.bhsmx.com/api/${tableName}/${equipo_tag}/${extra}`; // Construir la URL

    try { 
      const response = await fetch(url, { method: 'GET' });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error al obtener los datos de tags.');
      }

      const data = await response.json();
      setTags({
        assigned: data.assigned?.sort() || [], // Asignados ordenados
        unassigned: data.unassigned?.sort() || [], // No asignados ordenados
        cliente: data.cliente || null, // Cliente único, o null si no está presente
      });

      // Pasar el valor de `assigned` al componente padre
      if (onAssignedUpdate) {
        onAssignedUpdate(data.assigned || []);
      }

      // Establecer valores iniciales para los selectores desde los tags asignados
      setSelectedTags(
        data.assigned.slice(0, maxSelects).concat(Array(maxSelects).fill('')).slice(0, maxSelects)
      );
    } catch (error) {
      console.error('Error al realizar la solicitud:', error.message);
      setTags({ assigned: [], unassigned: [] });
    }
  };

  // Efecto para obtener tags cuando cambien `table` o `equipo_tag`
  useEffect(() => {
    if (table && equipo_tag) {
      fetchTags(table);
    } else {
      setTags({ assigned: [], unassigned: [] });
      setSelectedTags(Array(maxSelects).fill(''));
    } 
  }, [table, equipo_tag]);

  // Efecto para enviar los tags seleccionados al componente padre
  useEffect(() => {
    if (onSelectTags) {
      onSelectTags(selectedTags.filter((tag) => tag)); // Filtrar los tags seleccionados no vacíos antes de enviarlos
    }
  }, [selectedTags, onSelectTags]);

  // Manejar cambios en los selects individuales
  const handleSelectChange = (index, value) => {
    setSelectedTags((prevTags) => {
      const updatedTags = [...prevTags];
      updatedTags[index] = value; // Actualizar el valor seleccionado en el índice correspondiente
      return updatedTags;
    });
  };

  const handleDelete = async (tag) => {
    // Mostrar la alerta de confirmación
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar este archivo?"
    );
  
    if (confirmDelete) {
      // Si el usuario confirma, llama a la función de eliminación
      if (onDelete) {
        await onDelete({
          table,
          column: extra === '' ? "tag_equipo" : extra,
          id_interno: table === "grilletes" || table === "estrobos" ? tag : equipo_tag,
          estrobo: extra != "tag_equipo" ? estrobo : null,
          grilletes: extra != "tag_equipo" ? grilletes : null
        });
        await fetchTags(table);
  
        // Limpiar el valor seleccionado si coincide con el eliminado
        setSelectedTags((prevTags) =>
          prevTags.map((t) => (t === tag ? '' : t))
        );
      }
    } else {
      console.log("El usuario canceló la eliminación.");
    }
  };
  
  

  return (
    <div className="option-tag">
      {[...Array(maxSelects)].map((_, index) => (
        <div key={index} className="tag-selector">
          <label htmlFor={`tag-${index}`}>{index + 1}:</label>
          <select
            id={`tag-${index}`}
            name={`tag-${index}`}
            value={selectedTags[index] || ''} // Valor seleccionado o vacío
            onChange={(e) => handleSelectChange(index, e.target.value)} // Manejar cambios en el selector
            disabled={!isTagEnabled} // Deshabilitar si no está habilitado
            required
          >
            <option value="" disabled>
              {tags.unassigned.length === 0 && tags.assigned.length === 0
                ? 'Cargando...'
                : 'Seleccione una opción'}
            </option>
            {[...(tags.unassigned || []), ...(tags.assigned || [])]
              .sort((a, b) => a.localeCompare(b)) // Ordenar todos los tags combinados
              .filter((tag) => !selectedTags.includes(tag) || selectedTags[index] === tag) // Filtrar duplicados excepto el actual
              .map((tag, i) => (
                <option key={i} value={tag}>
                  {tag}
                </option>
              ))}
          </select>
          {tags.assigned.includes(selectedTags[index]) && (
            <button
              type="button"
              disabled={!isTagEnabled}
              onClick={() => handleDelete(selectedTags[index])}
            >
              Borrar
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default OptionTag;

