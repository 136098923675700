import React, { useState, useEffect } from "react";
import HorizontalCard from "./cardVisualizer.js";
import fetchAndTransformData from './api/organiceVisualizer.js';

const Visualizer = () => {
  const [items, setItems] = useState([]); // Estado para almacenar los datos obtenidos
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 9; // Máximo de elementos visibles

  useEffect(() => {
    // Llama a la API para obtener los datos
    const fetchData = async () => {
      const data = await fetchAndTransformData();
      setItems(data);
    };

    fetchData();
  }, []); // Se ejecuta solo al montar el componente

  // Determina los elementos actuales a mostrar
  const currentItems = items.slice(currentIndex, currentIndex + itemsPerPage);

  useEffect(() => {
    if (items.length === 0) return; // No configurar el intervalo si no hay datos

    // Configura el temporizador para cambiar los elementos cada 30 segundos
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + itemsPerPage;
        // Si se excede el tamaño del array, vuelve al inicio
        return nextIndex >= items.length ? 0 : nextIndex;
      });
    }, 30000); // Cambia cada 30 segundos

    return () => clearInterval(interval); // Limpia el intervalo al desmontar el componente
  }, [items, items.length]); // Ejecutar de nuevo si cambia el tamaño de `items`

  return (
    <div
      className="visualizer-Container"
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "12px", // Espaciado entre las tarjetas
        justifyContent: "center", // Centra el contenido horizontalmente
        padding: "12px", // Espaciado interno
      }}
    >
      {currentItems.map((item, index) => (
        <div
          key={index}
          style={{
            flex: "1 1 calc(33.33% - 12px)", // Cada tarjeta ocupa el 33.33% del ancho menos el espaciado
            maxWidth: "calc(33.33% - 12px)",
            boxSizing: "border-box", // Incluye padding y border en el ancho
          }}
        >
          <HorizontalCard {...item} />
        </div>
      ))}
    </div>
  );
};

export default Visualizer;
