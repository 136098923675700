import React from 'react';
import PropTypes from 'prop-types';

const ColorChangingCircle = ({parameter, text }) => {
  // Función para determinar el color basado en el parámetro
  const determineColor = (param) => {
    if (param === 'success') return '#6BAF59'; // Verde
    if (param === 'warning') return '#F1D100'; // Naranja
    if (param === 'error') return '#B00000'; // Rojo
    if (param === 'negative') return '#2E2E2E'; // Negro
    if (param === 'null') return 'transparent'; // Transparente
    return 'null'
    
  };

  const circleStyle = {
    width: '1.6rem',
    height: '1.6rem',
    borderRadius: '50%',
    backgroundColor: determineColor(parameter),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.9rem', // Ajusta el tamaño del texto
    fontWeight: 'bold',
    boxShadow: parameter === 'null' ? 'none' : '0 4px 6px rgba(0, 0, 0, 0.1)', // Sin sombra si es 'null'
    textAlign: 'center',
    lineHeight: '1.4rem', // Igual al tamaño del círculo
    color: parameter === 'null' ? 'transparent' : '#fff', // Esconde el texto si es null
    size: '100px'
  };

  return (
    <div style={circleStyle}>
        {text}
    </div>
  );
};

ColorChangingCircle.propTypes = {
  parameter: PropTypes.string.isRequired,
};
export default ColorChangingCircle;
