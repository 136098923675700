import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ColorChangingCircle from './indicatorLed';
import PropTypes from 'prop-types';


const HorizontalCard = ({
  id_interno = 'N/A',
  tipo = 'N/A',
  Pcarga = ['N/A', false],
  Ppnd = ['N/A', false],
  Pmtto = 'N/A',
  tag_estrobo = 'N/A',
  tag_grilletes = ['N/A', 'N/A'],
  factura = false,
  ubicacion = 'N/A',
  meses_1 = 'N/A',
  meses_2 = 'N/A',
  image1 = '', // Agrega una propiedad para la imagen
}) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        boxShadow: 3,
        height: 'auto',
      }}
    >
      {/* Contenido multimedia (Imagen) */}
      <CardMedia
        component="img"
        sx={{ maxWidth: '250px', objectFit: 'cover' }}
        image={image1}

        alt="Imagen de ejemplo"
      />
      {/* Contenido de texto */}
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CardContent>
          <Typography variant="h5" component="div">
          <strong>{id_interno}</strong>
          </Typography>
          <Typography variant="subtitle1" color="text.primary">
            {tipo}
          </Typography>
          <Divider sx={{ my: 2 }} />
          
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
                <ColorChangingCircle parameter={Pcarga[1]} text={meses_1}/>
                <Typography variant="body2" color="text.primary" >
                  <strong>Prueba de Carga:</strong> {Pcarga[0]}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ColorChangingCircle parameter={Ppnd[1]} text={meses_2} />
                <Typography variant="body2" color="text.primary">
                  <strong>PND:</strong> {Ppnd[0]}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ColorChangingCircle parameter={Pmtto === 'N/A' ? "warning" : "success"} text={Pmtto === 'N/A' ? "NO" : "OK"} />
                <Typography variant="body2" color="text.primary">
                  <strong>Mantenimiento:</strong> {Pmtto}
                </Typography>
              </Box>
              <Divider sx={{ my: 1 }} />
              {/* Grid para las columnas */}
          <Grid container spacing={1}>
            {/* Primera columna */}
           
            <Grid item xs={6}>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ColorChangingCircle parameter="null" />
                <Typography variant="body2" color="text.primary">
                  <strong>Estrobo:</strong>
                </Typography>

              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ColorChangingCircle parameter="null" />
              <Typography variant="body2" color="text.primary">
                {tag_estrobo || "N/A"}
                </Typography>
                </Box>
            </Grid>

            {/* Segunda columna */}
            <Grid item xs={6} paddingBottom={'10px'} >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ColorChangingCircle parameter="null" />
                <Typography variant="body2" color="text.primary">
                  <strong>Grilletes:</strong>
                </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ColorChangingCircle parameter="null" />
                <Typography variant="body2" color="text.primary">
                 {tag_grilletes[0]}({tag_grilletes[1]})
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="body2" color="text.primary">
            <strong>Factura:</strong>
          </Typography>
          <ColorChangingCircle parameter={factura ? 'success' : 'error'} text={factura ? 'OK' : 'NO'} /> 
          </Box>
          <Typography variant="body2" color="text.primary">
            <strong>Ubicación:</strong> {ubicacion || "N/A"}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

HorizontalCard.propTypes = {
  id_interno: PropTypes.string,
  tipo: PropTypes.string,
  Pcarga: PropTypes.array,
  Ppnd: PropTypes.array,
  Pmtto: PropTypes.string,
  tag_estrobo: PropTypes.string,
  tag_grilletes: PropTypes.array,
  factura: PropTypes.bool,
  ubicacion: PropTypes.string,
  meses_1: PropTypes.string,
  meses_2: PropTypes.string,
  image1: PropTypes.string,
};
export default HorizontalCard;
