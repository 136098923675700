const fetchAndTransformData = async () => {
  try {
    const response = await fetch('https://api.bhsmx.com/api/all-certifications');
    if (!response.ok) {
      throw new Error('Error al obtener datos de /api/all-certifications');
    }

    const rawData = await response.json();

    const transformData = async (data) => {
      const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear().toString().slice(-2);
        return `${day}/${month}/${year}`;
      };

      const calculateMonthsDifference = (startDate, endDate) => {
        if (!startDate || !endDate) return 'N/A';
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffYears = end.getUTCFullYear() - start.getUTCFullYear();
        const diffMonths = end.getUTCMonth() - start.getUTCMonth();
        return diffYears * 12 + diffMonths;
      };

      const getBackgroundColor = (month) => {
        if (month <= 0) return 'negative';
        if (month <= 3) return 'error';
        if (month > 3 && month <= 6) return 'warning';
        return 'success';
      };

      const transformGrilleteTag = (grilleteTag) => {
        if (!grilleteTag) return ['N/A', '(N/A)'];
        const tags = grilleteTag.split(/,\s*/);
        const prefix = tags[0].split('-')[0];
        const numbers = tags.map((tag) => tag.replace(prefix + '-', '')).join('');
        return [prefix, `${numbers}`];
      };
      const transformtipo = (tipo) => {
        const tableNameMapping = {
          spoolers: 'Spooler',
          bml: 'Base para Motor Lift',
          canastillas: 'Canastilla',
          base_carrete: 'Base Portacarrete',
          racks: 'Rack',
          cajas_herramientas: 'Caja de Herramientas',
        };
        
        return tableNameMapping[tipo]
      };

      const today = new Date();

      // Define rutas de imagen
      const imagePath = `/equipos/${data.id_interno}.jpeg`;
      // Verifica si la imagen existe

      return {
        id_interno: data.id_interno,
        tipo: transformtipo(data.source_table),
        Pcarga: [formatDate(data.carga_fecha), getBackgroundColor(calculateMonthsDifference(today, data.carga_next))],
        Ppnd: [formatDate(data.pnd_fecha), getBackgroundColor(calculateMonthsDifference(today, data.pnd_next))],
        Pmtto: formatDate(data.mtto_fecha) || 'N/A',
        tag_estrobo: data.estrobo_tag || 'N/A',
        tag_grilletes: transformGrilleteTag(data.grillete_tag),
        factura: true,
        ubicacion: data.ubicacion || 'N/A',
        meses_1: calculateMonthsDifference(today, data.carga_next).toString(),
        meses_2: calculateMonthsDifference(today, data.pnd_next).toString(),
        image1: imagePath,
      };
    };

    // Usa `Promise.all` para manejar todas las transformaciones asíncronas
    const transformedData = await Promise.all(rawData.map(transformData));

    return transformedData;
  } catch (error) {
    console.error('Error:', error.message);
    return [];
  }
};

export default fetchAndTransformData;
