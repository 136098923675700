import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography, Grid } from "@mui/material";

const AdminDashboard = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: 2,
        backgroundColor: "#f5f5f5",
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", color: "#333" }}>
        Interfaz Admin
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to="/register"
            sx={{
              padding: 2,
              fontSize: "1.2rem",
              borderRadius: "12px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            Registrar Usuario
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            component={Link}
            to="/certificados"
            sx={{
              padding: 2,
              fontSize: "1.2rem",
              borderRadius: "12px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            Certificados
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="success"
            fullWidth
            component={Link}
            to="/re-cer"
            sx={{
              padding: 2,
              fontSize: "1.2rem",
              borderRadius: "12px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            Editar Certificados
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="info"
            fullWidth
            component={Link}
            to="/visualizer"
            sx={{
              padding: 2,
              fontSize: "1.2rem",
              borderRadius: "12px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            Visualizador
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
