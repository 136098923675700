import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const DeleteFileForm = () => {
  const [table, setTable] = useState("");
  const [archivo, setArchivo] = useState("");
  const [files, setFiles] = useState([]); // Lista de archivos disponibles
  const [ids, setIds] = useState([]); // Declaración del estado para los IDs internos
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const tables = [
    { value: "base_carrete", label: "Base Carrete" },
    { value: "canastillas", label: "Canastillas" },
    { value: "spoolers", label: "Spoolers" },
    { value: "racks", label: "Racks" },
    { value: "estrobos", label: "Estrobos" },
    { value: "grilletes", label: "Grilletes" },
  ];

  useEffect(() => {
    if (archivo) {
      handleArchivoChange(archivo);
    }
  }, [archivo]);

  const handleTableChange = async (e) => {
    const selectedTable = e.target.value;
    setTable(selectedTable);
    setArchivo(""); // Reiniciar archivo seleccionado

    // Obtener los IDs internos de la tabla seleccionada
    try {
      const response = await fetch(`http://localhost:5000/api/update/${selectedTable}/ids`);
      if (!response.ok) throw new Error("Error al obtener los ids internos");
      const data = await response.json();
      setIds(data.ids); // Asignar los IDs al estado
    } catch (error) {
      console.error("Error al cargar los ids internos:", error.message);
      setIds([]); // Reiniciar los IDs en caso de error
    }
  };

  const handleArchivoChange = async (selectedArchivo) => {
    setArchivo(selectedArchivo); // Actualiza el estado de "archivo"

    try {
      setLoading(true);
      setFiles([]); // Limpia la lista de archivos antes de cargar nuevos
      const response = await fetch(`http://localhost:5000/api/files/${table}/${selectedArchivo}`);
      if (!response.ok) throw new Error("Error al obtener los archivos");

      const data = await response.json();
      console.log("Archivos recibidos:", data.files); // Verifica la respuesta del backend

      setFiles(data.files || []); // Actualiza la lista de archivos
    } catch (error) {
      console.error("Error al cargar los archivos:", error.message);
      setFiles([]); // Asegúrate de limpiar en caso de error
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (fileName) => {
    try {
      setLoading(true);
      setMessage("");
      const response = await fetch(`http://localhost:5000/api/delete`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ table, archivo, fileName }),
      });

      const result = await response.json();

      if (response.ok) {
        setMessage(`Archivo eliminado con éxito: ${fileName}`);
        setFiles(files.filter((file) => file !== fileName));
      } else {
        setMessage(`Error al eliminar el archivo: ${result.message}`);
      }
    } catch (error) {
      setMessage("Error de red. Por favor intenta nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Eliminar Archivos
      </Typography>

      <form style={{ width: "100%", maxWidth: 400 }}>
        {/* Selección de tabla */}
        <Box mb={3}>
          <FormControl fullWidth>
            <InputLabel>Tipo de Equipo</InputLabel>
            <Select value={table} onChange={handleTableChange}>
              {tables.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Selección de ID interno */}
        <Box mb={3}>
          <FormControl fullWidth>
            <InputLabel>ID Interno</InputLabel>
            <Select
              value={archivo}
              onChange={(e) => setArchivo(e.target.value)}
              disabled={!ids.length}
            >
              {ids.map((id) => (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Lista de archivos */}
        {loading ? (
          <Box mb={3} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {files.map((fileName) => (
              <ListItem key={fileName} divider>
                <ListItemText primary={fileName} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    color="secondary"
                    onClick={() => handleDelete(fileName)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}

        {message && (
          <Box mt={3}>
            <Alert severity={message.includes("éxito") ? "success" : "error"}>
              {message}
            </Alert>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default DeleteFileForm;
