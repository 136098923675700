import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  Input,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
} from "@mui/material";

const UpdateFileForm = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState("");
  const [archivo, setArchivo] = useState("");
  const [ids, setIds] = useState([]); // Lista de ids internos disponibles

  const tables = [
    { value: "base_carrete", label: "Base Carrete" },
    { value: "canastillas", label: "Canastillas" },
    { value: "spoolers", label: "Spoolers" },
    { value: "racks", label: "Racks" },
    { value: "estrobos", label: "Estrobos" },
    { value: "grilletes", label: "Grilletes" },
  ];

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleTableChange = async (e) => {
    const selectedTable = e.target.value;
    setTable(selectedTable);
    setArchivo(""); // Reiniciar archivo seleccionado

    // Obtener los IDs internos de la tabla seleccionada
    try {
      const response = await fetch(`http://localhost:5000/api/update/${selectedTable}/ids`);
      if (!response.ok) throw new Error("Error al obtener los ids internos");
      const data = await response.json();
      setIds(data.ids); // Asumimos que el backend devuelve { ids: [] }
    } catch (error) {
      console.error("Error al cargar los ids internos:", error.message);
      setIds([]);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!file || !table || !archivo) {
      setMessage("Por favor completa todos los campos.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("table", table);
    formData.append("archivo", archivo);
    console.log(formData)
    setLoading(true);
    setMessage("");

    try {
      const response = await fetch("http://localhost:5000/api/upload", {
        method: "POST",
        body: formData,
      });

      const result = await response.json();

      if (response.ok) {
        setMessage(`Archivo subido con éxito`);
      } else {
        setMessage("Error al subir el archivo.");
      }
    } catch (error) {
      setMessage("Error de red. Por favor intenta nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Actualizar Archivo
      </Typography>

      <form onSubmit={handleUpload} style={{ width: "100%", maxWidth: 400 }}>
        {/* Selección de tabla */}
        <Box mb={3}>
          <FormControl fullWidth>
            <InputLabel>Tipo de Equipo</InputLabel>
            <Select value={table} onChange={handleTableChange}>
              {tables.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Selección de ID interno */}
        <Box mb={3}>
          <FormControl fullWidth>
            <InputLabel>ID Interno</InputLabel>
            <Select
              value={archivo}
              onChange={(e) => setArchivo(e.target.value)}
              disabled={!ids.length}
            >
              {ids.map((id) => (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Selección de archivo */}
        <Box mb={3}>
          <Typography variant="body1" gutterBottom>
            Selecciona un archivo para subir:
          </Typography>
          <Input
            type="file"
            fullWidth
            onChange={handleFileChange}
            inputProps={{ accept: "*/*" }}
          />
        </Box>

        {loading ? (
          <Box mb={3} display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Subir Archivo
          </Button>
        )}

        {message && (
          <Box mt={3}>
            <Alert severity={message.includes("éxito") ? "success" : "error"}>
              {message}
            </Alert>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default UpdateFileForm;
