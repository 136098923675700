import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Importación corregida
import {
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Link,
} from '@mui/material';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api.bhsmx.com/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Guardar el token en localStorage
        localStorage.setItem('token', data.token);

        // Decodificar el token para obtener el tipo de usuario
        const decodedToken = jwtDecode(data.token);
        const userType = decodedToken.type; // Obtener el tipo de usuario del token

        // Redirección basada en el tipo de usuario
        if (userType === 'admin') {
          navigate('/dashboard'); // Redirige a la página de admin si es un administrador
        } else if (userType === 'user') {
          navigate('/certificados'); // Redirige a la página de certificados si es un usuario
        } else if (userType === 'client') {
          navigate('/client'); // Redirige a la página de cliente si es un cliente
        } else {
          throw new Error('Tipo de usuario desconocido'); // Mensaje de error genérico
        }
      } else {
        throw new Error(data.message || 'Error en la autenticación'); // Lanza un error si la respuesta no es OK
      }
    } catch (error) {
      setErrorMessage(error.message || 'Ocurrió un error inesperado'); // Manejo del mensaje de error
    }
  };

  return (
    <Box sx={{ height:'50vh', maxWidth: 450, margin: 'auto', mt: 5, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Iniciar Sesión
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Nombre de Usuario"
          variant="outlined"
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Contraseña"
          type="password"
          variant="outlined"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {errorMessage && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {errorMessage}
          </Alert>
        )}
        <Box sx={{ textAlign: 'right', mt: 1 }}>
          <Link href="#" underline="hover">
            ¿Has olvidado tu contraseña?
          </Link>
        </Box>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          sx={{ mt: 2 }}
        >
          Enviar
        </Button>
      </form>
    </Box>
  );
}

export default Login;
