import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import axios from 'axios';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://api.bhsmx.com/api/register', {
        username,
        password,
      });
      setMessage(response.data.message);
      setUsername('');
      setPassword('');
    } catch (error) {
      setMessage(error.response ? error.response.data.message : 'Error al registrarse');
    }
  };

  return (
    <Box sx={{ height: '50vh', width: '80vw', maxWidth:450, margin: '0 auto', textAlign: 'center', mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Registro de Usuario
      </Typography>
      <form onSubmit={handleRegister}>
        <TextField
          fullWidth
          label="Nombre de Usuario"
          variant="outlined"
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Contraseña"
          type="password"
          variant="outlined"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          sx={{ mt: 2 }}
        >
          Registrar
        </Button>
      </form>
      {message && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Register;
