import React, { useState, useEffect } from 'react';
import './ReCertificado.css';
import OptionTag from './option-tag';
import YesNoCheckbox from './YesNoCheckBox';
import { updateData, deleteData } from './api/updateData.js';

const optionsArray = [
  'Bases para Carrete',
  'Bases para Motor Lift',
  'Cajas de Herramientas',
  'Canastillas',
  'Racks',
];

const mapInverseTableName = (name) => {
  const tableMapping2 = {
    'base_carrete': 'Bases para Carrete',
    'bml': 'Bases para Motor Lift',
    'cajas_herramientas': 'Cajas de Herramientas',
    'canastillas': 'Canastillas',
    'eras': 'Eras',
    'extintores': 'Extintores',
    'racks': 'Racks',
    'spoolers': 'Spoolers',
  };

  const key = Object.keys(tableMapping2).find((key) => tableMapping2[key] === name);
  return key || `Nombre no reconocido: ${name}`;
};

const ReCertificados = () => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [tags, setTags] = useState([]);
  const [isTagEnabled, setIsTagEnabled] = useState(false);
  const [selectedEstrobo, setSelectedEstrobo] = useState([]);
  const [selectedGrilletes, setSelectedGrilletes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState('');
  const [selectedUbicacion, setSelectedUbicacion] = useState('');
  const [isEstroboEnabled, setIsEstroboEnabled] = useState(false);
  const [isGrilleteEnabled, setIsGrilleteEnabled] = useState(false);
  const [isClienteEnabled, setIsClienteEnabled] = useState(false);
  const [isUbicacionEnabled, setIsUbicacionEnabled] = useState(false);
  const [assignedEstrobo, setAssignedEstrobo] = useState([]);
  const [assignedGrilletes, setAssignedGrilletes] = useState([]);
  const [assignedCliente, setAssignedCliente] = useState([]);
  const [assignedUbicacion, setAssignedUbicacion] = useState([]);

  const fetchTags = async (category) => {
    const tableMapping = {
      'Bases para Carrete': 'base_carrete',
      'Bases para Motor Lift': 'bml',
      'Cajas de Herramientas': 'cajas_herramientas',
      'Canastillas': 'canastillas',
      'Racks': 'racks',
      'Spoolers': 'spoolers',
      'Torquimetros': 'torquimetros',
    };

    const tableName = tableMapping[category];
    if (!tableName) {
      console.error(`Nombre de tabla no reconocido: ${category}`);
      setTags([]);
      return;
    }

    const url = `https://api.bhsmx.com/api/${tableName}/tag`;

    try {
      const response = await fetch(url, { method: 'GET' });
      if (!response.ok) throw new Error('Error al obtener los tags');
      const data = await response.json();
      setTags(data);
    } catch (error) {
      console.error('Error en la solicitud:', error.message);
      setTags([]);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      fetchTags(selectedCategory);
      setIsTagEnabled(true);
    } else {
      setTags([]);
      setIsTagEnabled(false);
    }
  }, [selectedCategory]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Actualizar valores según el estado habilitado
    const updatedCliente = isClienteEnabled ? selectedCliente : null;
    const updatedGrilletes = isGrilleteEnabled ? selectedGrilletes : null;
    const updatedEstrobo = isEstroboEnabled ? selectedEstrobo : null;
    const updatedUbicacion = isUbicacionEnabled ? selectedUbicacion : null;
  
    // Validar categoría y tag seleccionados
    if (!selectedCategory || !selectedTag) {
      console.error('Por favor, selecciona una categoría y un tag válido.');
      return;
    }
  
    // Llamar a `updateData` para realizar las actualizaciones
    try {
      await updateData(
        mapInverseTableName(selectedCategory),
        selectedTag,
        updatedCliente,
        updatedEstrobo,
        updatedGrilletes,
        assignedEstrobo,
        assignedGrilletes,
        updatedUbicacion,
        assignedUbicacion
      );
  
      // Imprimir datos procesados en consola para depuración
      console.log({
        category: mapInverseTableName(selectedCategory),
        tag: selectedTag,
        estrobo: updatedEstrobo,
        grilletes: updatedGrilletes,
        cliente: updatedCliente,
        grillete_actual: assignedGrilletes,
        estrobo_actual: assignedEstrobo,
        cliente_actual: assignedCliente,
        ubicacion_actual: assignedUbicacion,
        ubicacion: updatedUbicacion,
      });
  
      // Actualizar los valores asignados solo si los nuevos no son null
      if (updatedCliente !== null) {
        setAssignedCliente(updatedCliente);
      }
      if (updatedEstrobo !== null) {
        setAssignedEstrobo(updatedEstrobo);
      }
      if (updatedGrilletes !== null) {
        setAssignedGrilletes(updatedGrilletes);
      }
      if (updatedUbicacion !== null) {
        setAssignedUbicacion(updatedUbicacion);
      }
    } catch (error) {
      console.error('Error al realizar la actualización:', error.message);
    }
  };  
  

  return (
    <div className="MainContainer-ReCertificados">
      <div className="Recertificados-Container">
        <div className="Recertificados-Wrapper">
          <h2>Cambio de Información</h2>
        </div>
        <form onSubmit={handleSubmit}>

          <div className='options-main-container'>
          {/* Categoría */}
          <div className="option-db">
            <label htmlFor="categoria">Selecciona una Categoría:</label>
            <select
              id="categoria"
              name="categoria"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              defaultValue=""
            >
              <option value="" disabled>
                Seleccione una categoría
              </option>
              {optionsArray.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select> 
          </div>

          {/* Tag */}
          <div className="option-tag">
            <label htmlFor="tag">Selecciona el Tag del Equipo:</label>
            <select
              id="tag"
              name="tag"
              defaultValue=""
              value={selectedTag}
              onChange={(e) => setSelectedTag(e.target.value)}
              disabled={!isTagEnabled}
              required
            >
              <option value="" disabled>
                {tags.length === 0 ? 'Seleccione una categoría' : 'Seleccione un tag'}
              </option>
              {tags.sort((a, b) => a.localeCompare(b)).map((tag, index) => (
                <option key={index} value={tag}>
                  {tag}
                </option>
              ))}
            </select>
          </div>
          </div>

          <div className='select-accesorio'>
          {/* Estrobos */}
          <div className="accesorio-container">
            <h2>Estrobos</h2>
            <YesNoCheckbox onSelectionChange={(isYes) => setIsEstroboEnabled(isYes)} />
            <OptionTag
              isTagEnabled={isEstroboEnabled}
              table="estrobos"
              maxSelects={1}
              onSelectTags={setSelectedEstrobo}
              onAssignedUpdate={(assigned) => setAssignedEstrobo(assigned)}
              equipo_tag={selectedTag}
              onDelete={deleteData}
            />
          </div>

          {/* Grilletes */}
          <div className="accesorio-container">
            <h2>Grilletes</h2>
            <YesNoCheckbox onSelectionChange={(isYes) => setIsGrilleteEnabled(isYes)} />
            <OptionTag
              isTagEnabled={isGrilleteEnabled}
              table="grilletes"
              maxSelects={4}
              onSelectTags={setSelectedGrilletes}
              onAssignedUpdate={(assigned) => setAssignedGrilletes(assigned)}
              equipo_tag={selectedTag}
              onDelete={deleteData}
            />
          </div>

          {/* Cliente */}
          <div className="accesorio-container">
            <h2>Cliente</h2>
            <YesNoCheckbox onSelectionChange={(isYes) => setIsClienteEnabled(isYes)} />
            <OptionTag
              isTagEnabled={isClienteEnabled}
              table={mapInverseTableName(selectedCategory)}
              maxSelects={1}
              onSelectTags={setSelectedCliente}
              onAssignedUpdate={(assigned) => setAssignedCliente(assigned)}
              equipo_tag={selectedTag}
              onDelete={deleteData}
              extra='cliente'
            />
          </div>
            {/* Ubicacion */}
            <div className="accesorio-container">
            <h2>Ubicacion</h2>
            <YesNoCheckbox onSelectionChange={(isYes) => setIsUbicacionEnabled(isYes)} />
            <OptionTag
              isTagEnabled={isUbicacionEnabled}
              table={mapInverseTableName(selectedCategory)}
              maxSelects={1}
              onSelectTags={setSelectedUbicacion}
              onAssignedUpdate={(assigned) => setAssignedUbicacion(assigned)}
              equipo_tag={selectedTag}
              onDelete={deleteData}
              extra='ubicacion'
              estrobo={selectedEstrobo}
              grilletes={selectedGrilletes}
            />
          </div>
          </div>
          <button className="submit-login" type="submit">
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
};

export default ReCertificados;
